import Swagger from 'swagger-client'
import clientRequest from "../constants/clientRequest"
import config from '../config'
import uuidv1 from "uuid/v1";
import { upload_single_object_to_s3 } from '../utils/s3_single_upload'
import { BUCKETS } from '../constants/constants';

const tag = 'api/admin';

export const loginApi = (email, password) => {
    return clientRequest
        .then(client => {
            // Tags interface
            return client.apis[tag].post_admin_login({
                payload: {
                    email,
                    password,
                }
            });
        })
};

export const logoutApi = (token) => {
    return clientRequest.then(client => {
        // Tags interface
        return client.apis[tag].post_admin_logout({
            payload: {
                token,
            }
        });
    })
};

// export const createRecruiterApi = (token, email, first_name, last_name, password, ai_required, evaluation_required, video_annotation_required, company_group, transcribe, logo, logo_pic, download_video, add_file, share_pdf_report, report_type, mcq, sortQA) => {
export const createRecruiterApi = (token, recruiter_info) => {
    // console.debug("admin.js: recruiter_info",recruiter_info)
    // const res = await createRecruiterApi(
    //     token = token,
    //     email = recruiter_info.email,
    //     first_name = recruiter_info.firstName,
    //     last_name = recruiter_info.lastName,
    //     password = recruiter_info.password,
    //     videoInterview = recruiter_info.videoInterview ? 1 : 0,
    //     videoInterviewModel = (recruiter_info.videoInterview && recruiter_info.videoInterviewModel === "1" ? 1 : 0),
    //     ai_required = (recruiter_info.videoInterview && recruiter_info.aiRequired) ? 1 : 0,
    //     evaluation_required = 1,
    //     video_annotation_required = 1,
    //     company_group = recruiter_info.companyGroup,
    //     transcribe = recruiter_info.transcribe ? 1 : 0,
    //     logo = recruiter_info.videoInterviewModel ? recruiter_info.logo ? 1 : 0,
    //     logo_pic = recruiter_info.logo_pic,
    //     download_video = recruiter_info.download_video ? 1 : 0,
    //     add_file = recruiter_info.addFile ? 1 : 0,
    //     share_pdf_report = recruiter_info.shareReport ? 1 : 0,
    //     report_type = recruiter_info.shareReport && parseInt(recruiter_info.reportType))
    const {
        email,
        firstName,
        lastName,
        password,
        aiRequired,
        // evaluation_required,
        // video_annotation_required,
        companyGroup,
        trainingReport,
        videoInterview,
        mcq,
        sortQA,
        assessmentGeneration,
        virtualAvatar,
        videoInterviewModel,
        manualReview,
        displayEmotion,
        transcribe,
        parseCVFunction,
        logo,
        logo_pic,
        download_video,
        addFile,
        shareReport,
        reportType,
        Share_with_Candidate,
        dataAgreement,
    } = recruiter_info

    const formData = new FormData()
    formData.append("token", token)
    formData.append("password", password)
    formData.append("email", email)
    formData.append("first_name", firstName)
    formData.append("last_name", lastName)

    formData.append("mcq", mcq ? 1 : 0)
    formData.append("sort_qa", sortQA ? 1 : 0)
    formData.append("training_report", trainingReport ? 1 : 0)
    formData.append("video_interview", videoInterview ? 1 : 0)
    formData.append("video_interview_model", videoInterviewModel === "0" ? "simplified" : "full")
    formData.append("assessment_generation", assessmentGeneration ? 1 : 0)
    formData.append("virtual_avatar", virtualAvatar ? 1 : 0)

    formData.append("evaluation_required", videoInterview ? 1 : 0)
    formData.append("video_annotation_required", videoInterview ? 1 : 0)

    formData.append("company_group", companyGroup)
    formData.append("ai_required", aiRequired ? 1 : 0)
    formData.append("manual_review_required", manualReview ? 1 : 0)
    formData.append("display_emotion", displayEmotion ? 1 : 0)
    formData.append("add_file", addFile ? 1 : 0)
    formData.append("share_pdf_report", shareReport ? 1 : 0)
    formData.append("Share_with_Candidate", Share_with_Candidate ? 1 : 0)
    shareReport && formData.append("pdf_report_type", reportType ? "withscore" : "withoutscore")
    if (videoInterview && transcribe) {
        formData.append("transcribe", transcribe ? 1 : 0)
    }
    if (videoInterview && parseCVFunction) {
        formData.append("parse_cv_function", parseCVFunction ? 1 : 0)
    }
    if (videoInterview && videoInterviewModel === "1" && logo) {
        formData.append("logo", logo ? 1 : 0)
    }
    if (videoInterview && download_video) {
        formData.append("download_video", download_video ? 1 : 0)
    }

    if (logo && logo_pic && logo_pic instanceof Blob) {
        //formData.append("logo_pic", logo_pic, `${uuidv1()}${logo_pic.name}`)
        let obj_name = `company_logos/${uuidv1()}${logo_pic.name}`  //`interviewer_images/interviewerImage-${jobId}${layout.interviewerImageExtension}`
        formData.append("logo_pic", obj_name)//`interviewer_images/interviewerImage-${jobId}-${uuidv1()}${layout.interviewerImageExtension}`);

        // uploading object to s3
        let obj = logo_pic
        upload_single_object_to_s3(BUCKETS.MEDIA_BUCKET, obj, obj_name, token)
    }

    if (dataAgreement) {
        formData.append("data_agreement", JSON.stringify(dataAgreement))
    }

    // console.debug("admin.js: formData ready: ",formData)
    const request = {
        url: `${config.baseUrl}/api/admin/create_recruiter`,
        method: 'POST',
        body: formData,
    };
    return Swagger.http(request)
    // return recruiter_info
}

export const forgetPassword = (email) => {
    return clientRequest.then(client => {
        // Tags Interface
        return clientRequest.then(client => {
            // Tags Interface
            return client.apis[tag].post_admin_forget_password({
                payload: {
                    email,
                }
            })
        })
    })
}

export const resetPassword = (new_password, token) => {
    return clientRequest.then(client => {
        // Tags Interface
        return client.apis[tag].post_admin_reset_password({
            payload: {
                new_password,
                token,
            }
        })
    })
}

export const getRecruitersInfoApi = (token, pagination = {}) => {
    const {
        pageNum = 1,
        selectAll = false,
        limit: paginationLimit = 0,
        sort: paginationSort = { sortBy: "registerAt", order: -1 },
        filter: paginationFilter = { searchTerm: "" },
    } = pagination;

    const limit = !selectAll && paginationLimit > 0 ? paginationLimit : 0;
    const page = pageNum;
    const sortBy = paginationSort?.sortBy ?? "registerAt";
    const order = paginationSort?.order;
    const searchTerm = paginationFilter?.searchTerm;

    
    return clientRequest.then(client => {
        return client.apis[tag].post_get_recruiters_info({
            payload: {
                token,
                page,
                limit,
                sort: sortBy,
                order,
                searchTerm,
            }
        });
    });
};


export const getRecruiterInfoApi = (token, email) => {
    return clientRequest.then(client => {
        // Tags interface
        return client.apis[tag].post_get_recruiter_info({
            payload: {
                token,
                email
            }
        });
    })
}

export const getUsageApi = (token, company_groups) => {
    return clientRequest.then(client => {
        // Tags interface
        return client.apis[tag].post_get_usage_info({
            payload: {
                token,
                company_groups
            }
        });
    })
}

export const fozenRecruiterAccApi = (token, recruiter_emails) => {
    return clientRequest.then(client => {
        // Tags interface
        return client.apis[tag].post_fozen_recruiter({
            payload: {
                token,
                recruiter_emails
            }
        });
    })
}

export const reactivateRecruiterAccApi = (token, recruiter_emails) => {
    return clientRequest.then(client => {
        // Tags interface
        return client.apis[tag].post_reactivate_recruiter({
            payload: {
                token,
                recruiter_emails
            }
        });
    })
}

export const toggleRecruiterDataAgreementApi = (token, recruiter_emails, status) => {
    return clientRequest.then(client => {
        // Tags interface
        return client.apis[tag].post_toggle_data_agreement({
            payload: {
                token,
                recruiter_emails,
                status,
            }
        });
    })
}

export const deleteRecruiterAccApi = (token, recruiter_emails) => {
    return clientRequest.then(client => {
        // Tags interface
        return client.apis[tag].post_delete_recruiter({
            payload: {
                token,
                recruiter_emails
            }
        });
    })
}

export const getPurchasesList = (token) => {
    return clientRequest.then(client => {
        // Tags interface
        return client.apis[tag].post_get_purchases_list({
            payload: {
                token,
            }
        });
    })
}

export const addAllocation = (token, allocations) => {
    return clientRequest.then(client => {
        // Tags interface
        return client.apis[tag].post_add_allocation({
            payload: {
                token,
                allocations
            }
        });
    })
}

//export const updateRecruiterApi = ( token, email, manual_review_required=null ) => {
//  const d = new FormData()
//  d.append( 'token', token )
//  d.append( 'email', email )
//  if( ! isNil( manual_review_required ) ) {
//    d.append( 'manual_review_required', manual_review_required )
//  }
//  const request = {
//    url: `${config.baseUrl}/api/admin/update_recruiter`,
//    method: 'POST',
//    body: d,
//  }
//  return Swagger.http( request )
//}

export const actAsApi = (token, recruiter) => {
    return clientRequest.then(client => {
        // Tags interface
        return client.apis[tag].post_act_as({
            payload: {
                token,
                recruiter,
            }
        })
    })
}

export const getAdminProfileApi = (token) => {
    return clientRequest.then(client => {
        // Tags interface
        return client.apis[tag].post_get_admin_profile({
            payload: {
                token
            }
        });
    })
}

export const setAdminProfileApi = async (token, values) => {

    const formData = new FormData();
    formData.append("token", token);
    if (values.companyLogo && values.companyLogo instanceof Blob) {
        //formData.append("company_logo", values.companyLogo, `${uuidv1()}${values.companyLogoExtension}`);

        let admin_logo_name = `company_logos/profilePicture_${uuidv1()}${values.companyLogoExtension}`
        formData.append("company_logo", admin_logo_name) //`company_videos/companyVideo-${jobId}-${uuidv1()}${layout.companyVideoExtension}`);
        let admin_logo_file = values.companyLogo
        await upload_single_object_to_s3(BUCKETS.MEDIA_BUCKET, admin_logo_file, admin_logo_name, token)

    }
    if (values.companyLogo === null) formData.append("company_logo", null) //`company_videos/companyVideo-${jobId}-${uuidv1()}${layout.companyVideoExtension}`);
    // else {
    //     formData.append("company_logo_string", values.companyLogo);
    // }
    formData.append("company_name", values.companyName);
    formData.append("cs_email", values.CSEmail);

    formData.append("purpose", values.purpose) // opening||add_quota||suspension.....
    formData.append("status", values.status) // bool(true||false)
    formData.append("email", values.email) // email title and email content
    formData.append("attachment", values.attachment) // email atttachment
    formData.append("origin_attachment", values.origin_attachment)// remove previous attachment

    const request = {
        url: `${config.baseUrl}/api/admin/set_admin_profile`,
        method: 'POST',
        body: formData,
    };
    return Swagger.http(request)
}

export const adminSendEmailApi = (token, reciever, subject, content, attachment) => {
    const formData = new FormData()
    formData.append("token", token)
    formData.append("reciever", reciever)
    formData.append("subject", subject)
    formData.append("content", content)
    formData.append("attachment", attachment)
    const request = {
        url: `${config.baseUrl}/api/admin/admin_send_email`,
        method: 'POST',
        body: formData,
    };
    return Swagger.http(request)
}

export const adminCheckIsCouponExist = (token, competencyInput) => {
    return clientRequest.then(client => {
        return client.apis[tag].post_admin_check_is_coupon_exist({
            payload: {
                token: token,
                competencyInput: competencyInput
            }
        })
    })
}

export const adminGetExtraCompetencyList = (token, email) => {
    return clientRequest.then(client => {
        return client.apis[tag].post_admin_get_competency({
            payload: {
                token: token,
                email: email,
            }
        })
    })
}

export const adminAddExtraCompetencyList = (token, tempCompetencyList, email) => {
    return clientRequest.then(client => {
        return client.apis[tag].post_admin_add_competency({
            payload: {
                token: token,
                "competencyList": tempCompetencyList,
                "email": email
            }
        })
    })
}

export const edit_admin_passPolicy = (args) => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].post_set_admin_pass_policy({
      payload: args
    })
  })
}

export const get_admin_passPolicy_api = (token, authorizedSetting = false) => {
  return clientRequest.then(client => {
    // Tags interface
    return client.apis[tag].post_get_admin_pass_policy({
      payload: {
        token: token,
        authorizedSetting: authorizedSetting,
      }
    })
  })
}


export const admin_generate_password = (token) => {
    return clientRequest.then(client => {
      // Tags interface
      return client.apis[tag].post_generate_password({
        payload: {
          token
        }
      })
    })
  }

const admin = {
    loginApi,
    logoutApi,
    getRecruitersInfoApi,
    getRecruiterInfoApi,
    getUsageApi,
    fozenRecruiterAccApi,
    reactivateRecruiterAccApi,
    toggleRecruiterDataAgreementApi,
    deleteRecruiterAccApi,
    getPurchasesList,
    addAllocation,
    //    updateRecruiterApi,
    actAsApi,
    forgetPassword,
    resetPassword,
    getAdminProfileApi,
    setAdminProfileApi,
    adminSendEmailApi,
    adminCheckIsCouponExist,
    adminGetExtraCompetencyList,
    adminAddExtraCompetencyList
};

export default admin;
import clientRequest from "../constants/clientRequest"

const tag = 'api/reports';

export const getInfo = (email, job_id, token, role, interview_id) => {
    return clientRequest
        .then(client => {
            // Tags interface
            return client.apis[tag].post_get_info({
                payload: {
                    email,
                    job_id,
                    token,
                    role,
                    interview_id
                }
            });
        })
};
export const getInfoByGuest = (token) => {
    return clientRequest
        .then(client => {
            // Tags interface
            return client.apis[tag].post_get_info_by_invitation_token({
                payload: {
                    token
                }
            });
        })
};

export const getReport = (interview_id, token) => {

    console.log("getReport: ", "Hello World!")
    console.log("interview_id in getReport: ", interview_id)

    return clientRequest
        .then(client => {
            // Tags interface
            return client.apis[tag].post_get_report({
                payload: {
                    interview_id,
                    token,
                }
            })
        })
};

export const getReportByGuest = (token) => {
    return clientRequest
        .then(client => {
            // Tags interface
            return client.apis[tag].post_get_report_by_invitation_token({
                payload: {
                    token
                }
            });
        })
};


export const getVideo = (interview_id, label, num, token) => {
    return clientRequest
        .then(client => {
            // Tags interface
            return client.apis[tag].post_get_report_video({
                payload: {
                    interview_id,
                    num,
                    label,
                    token,
                }
            });
        })
};

export const getVideoByGuest = (token, label, num) => {
    return clientRequest
        .then(client => {
            // Tags interface
            return client.apis[tag].post_get_video_by_invitation_token({
                payload: {
                    token,
                    label,
                    num,
                }
            });
        })
};

export const submitComments = (jobId, email, self_scoring, totalScore, recruiter_token, interview_id) => {
    return clientRequest
        .then(client => {
            // Tags interface
            return client.apis[tag].post_submit_self_scoring({
                payload: {
                    job_id: jobId,
                    email,
                    self_scoring,
                    overall_score: totalScore,
                    recruiter_token,
                    interview_id
                }
            });
        })
};

export const submitCommentsByGuest = (token, scoring) => {
    return clientRequest
        .then(client => {
            // Tags interface
            return client.apis[tag].post_submit_invited_evaluation({
                payload: {
                    token,
                    scoring
                }
            });
        })
};


export const retrieveComments = (jobId, email, token, interview_id) => {
    return clientRequest
        .then(client => {
            // Tags interface
            return client.apis[tag].post_retrieve_self_scoring({
                payload: {
                    job_id: jobId,
                    email,
                    token,
                    interview_id
                }
            });
        })
};

export const getReviewerScoring = (token, jobId, email, interview_id) => clientRequest.then(client => {
    console.debug('getReviewerScoring:', {
        token,
        job_id: jobId,
        email,
        interview_id
    })
    return client.apis[tag].post_get_reviewer_scoring({
        payload: {
            token,
            job_id: jobId,
            email,
            interview_id
        }
    })
})

// export const submitReviewerScoring = (token, jobId, email, scoring, interview_id) => clientRequest.then(client => {

//     return client.apis[tag].post_submit_reviewer_scoring({
//         payload: {
//             token,
//             job_id: jobId,
//             email,
//             scoring,
//             interview_id
//         }
//     })
// })

// export const getQuestionScoring = ()

export const getPdfReportApi = ( token, jobId, interview_id, lang ) => clientRequest.then( client => {
  console.debug( 'getPdfReportApi:', { token, job_id: jobId, interview_id, lang } )
  return client.apis[ tag ].post_get_pdf_report( {
    payload: {
      token,
      job_id: jobId,
      interview_id,
      lang
    }
  } )
} )

export const getPdfTrainingReportApi = ( token, jobId, interview_id, lang ) => clientRequest.then( client => {
    console.debug( 'getPdfReportApi:', { token, job_id: jobId, interview_id, lang } )
    return client.apis[ tag ].post_get_pdf_training_report( {
      payload: {
        token,
        job_id: jobId,
        interview_id,
        lang
      }
    } )
  } )

export const getPdfReportListApi = ( token, jobId, interview_id, lang ) => clientRequest.then( client => {
    console.debug( 'getPdfReportApi:', { token, job_id: jobId, interview_id, lang } )
    return client.apis[ tag ].post_get_pdf_report_list( {
      payload: {
        token,
        job_id: jobId,
        interview_id,
        lang
      }
    } )
  } )

export const getEmotionsApi = ( token, jobId, interview_id, questionId ) => clientRequest.then( client => {
  const payload = { token, job_id: jobId, interview_id, question_id: questionId }
  console.debug( 'getEmotionsApi:', payload )
  return client.apis[ tag ].post_get_emotions( { payload } )
} )

export const listS3FilesApi = ( token, interviewId ) => clientRequest.then( client => {
  const payload = { token, interview_id: interviewId }
  console.debug( 'listS3FilesApi:', payload )
  return client.apis[ tag ].post_reports_list_s3_files( { payload } )
} )

export const transferS3FileApi = ( token, interviewId, filename ) => clientRequest.then( client => {
  const payload = { token, interview_id: interviewId, filename }
  console.debug( 'transferS3FileApi:', payload )
  return client.apis[ tag ].post_reports_transfer_s3_file( { payload } )
} )

export const fetchMultiquestionAnswersApi = ( candidate_token ) => clientRequest.then( client => {
  return client.apis[tag].post_fetch_multiquestion_answers({
    payload: {
        candidate_token
    }
  })
})

export const checkUpdateCandidateCompleteStatusAPI = ( token ) => clientRequest.then( client => {
    return client.apis[tag].post_check_update_candidate_complete_status({
        payload: {
            token
        }
    })
})

//with answer, updating the report
//without answer, generating the report
export const submitMultiquestionAnswersApi = ( answer=null, candidate_token, current_question_test, question_index ) => clientRequest.then( client => {
    let check = ![null, undefined].includes(answer) && typeof answer === 'string'
    return client.apis[tag].post_submit_multiquestion_answers(
        check ?
        {
            payload: {
                answer,
                candidate_token,
                current_question_test,
                question_index
            }
        }
        :
        {
            payload: {
                candidate_token,
                current_question_test,
                question_index
            }
        }
    )
})

export const checkMultipleChoiceAnswersApi = (job_id, interview_id, token) => clientRequest.then (client => {
    return client.apis[tag].post_check_multiple_choice_answers({
        payload: {
            job_id,
            interview_id,
            token
        }
    })
})

export const checkShortQuestionAnswersApi = (interview_id,  question_num, question_task, result, total_score, token ) => clientRequest.then (client => {
    return client.apis[tag].post_check_short_question_answers({
        payload: {
            interview_id,
            question_num,
            question_task,
            result,
            total_score,
            token
        }
    })
})

export const fetchShortQuestionAnswerApi = ( job_id, interview_id, token ) => clientRequest.then (client => {
    return client.apis[tag].post_fetch_short_question_answer({
        payload: {
            interview_id,
            job_id,
            token
        }
    })
})

export const fetchAllCandidatesTotalScore = ( recruiter_token, job_id ) => clientRequest.then (client => {
    return client.apis[tag].post_fetch_all_candidates_total_score({
        payload: {
            recruiter_token,
            job_id
        }
    })
})

export const fetchQuestionScore = (token, interview_id, question_competency) => clientRequest.then (client => {
    return client.apis[tag].post_fetch_question_score({
        payload: {
            token,
            interview_id,
            question_competency
        }
    })
})

export const fetchQuestionAiCompetencyAnalysis = (token, interview_id, length) => clientRequest.then (client => {
    return client.apis[tag].post_fetch_question_ai_competency_analysis({
        payload: {
            token,
            interview_id,
            length
        }
    })
})

export const fetchAiStarAnalysis = (interview_id, num, token) => clientRequest.then (client => {
    return client.apis[tag].post_fetch_ai_star_analysis({
        payload: {
            interview_id,
            num,
            token
        }
    })
})

export const submitQuestionScore = (token, interview_id, job_id, interview_model, score, question_competency, close_task) => clientRequest.then (client => {
    return client.apis[tag].post_submit_question_score({
        payload: {
            token,
            interview_id,
            job_id,
            interview_model,
            score,
            question_competency,
            close_task
        }
    })
})

export const submitOverallScore = (token, interview_id, job_id, score, question_competency, close_task) => clientRequest.then (client => {
    return client.apis[tag].post_submit_overall_score({
        payload: {
            token,
            interview_id,
            job_id,
            score,
            question_competency,
            close_task
        }
    })
})

export const calculateQuestionOverallScores = (token, interview_id, job_id, interview_model, score, question_competency) => clientRequest.then (client => {
    return client.apis[tag].post_calculate_candidate_scoring({
        payload: {
            token,
            interview_id,
            job_id,
            interview_model,
            score,
            question_competency,
        }
    })
})

export const calculateOverallScores = (token, interview_id, job_id, interview_model, score, question_competency) => clientRequest.then (client => {
    return client.apis[tag].post_calculate_candidate_overall_scoring({
        payload: {
            token,
            interview_id,
            job_id,
            interview_model,
            score,
            question_competency,
        }
    })
})



const reports = {
    getInfo,
    getInfoByGuest,
    getReport,
    getVideo,
    getVideoByGuest,
    submitComments,
    retrieveComments,
    getReviewerScoring,
    // submitReviewerScoring,
    submitCommentsByGuest,
    getPdfReportApi,
    getEmotionsApi,
    listS3FilesApi,
    transferS3FileApi,
    fetchMultiquestionAnswersApi,
    checkMultipleChoiceAnswersApi,
    checkShortQuestionAnswersApi,
    fetchShortQuestionAnswerApi,
    fetchAllCandidatesTotalScore,
    fetchQuestionScore,
    submitQuestionScore,
    fetchQuestionAiCompetencyAnalysis,
    submitOverallScore,
    fetchAiStarAnalysis,
    calculateQuestionOverallScores,
    calculateOverallScores,
};

export default reports;

import React, {useState, useRef, createContext, useContext, useMemo } from "react";
import { WebStorageStateStore } from "oidc-client-ts";
import { AuthProvider } from "react-oidc-context";

import { neufastTenant } from "./neufastTenant";
import { kbtgTenant } from "./kbtgTenant";
import { getCookie, removeCookie, setCookie } from "../../utils/cookieUtils";

const OpenIdInstanceContext = createContext();

export const OPENID_STATE = {
    "INITIALIZE": "initialize",
    "LOADING": "loading",
    "READY": "ready"
}

/**
 * Object to set Default UserManagerSettings
 * that will be used across all openid authorities
 */
const defaultConfigs = {
    scope: "profile openid email",
    response_type: "code",
    onSigninCallback:  (_user) => {
        console.log("Auth System: user: ", _user)
    },
    monitorSession: true,
    monitorAnonymousSession: true,
    revokeTokensOnSignout: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
}

export const OpenIdProvider = ({children}) => {
    const oidcConfigRef = useRef(null)
    
    console.log("Auth System: Default Check: authority: useRef:", oidcConfigRef.current?.authority)

    /**
     * state is used to track the mounting of this provider
     * and the switching between different openid authorities
     * 
     * INITIALIZE = Initial mounting of the component
     * LOADING = Prearing to update openid tenant
     * READY = open id tenant is loaded in and ready to be used.
     */
    const [state, setState] = useState(OPENID_STATE.INITIALIZE)

    /**
     * currentTenantRef is used to denote the currently active
     * openid authority and the utility function switches
     * between the registered ones
     */
    const currentTenantRef = useRef("default")
    const setCurrentTenant = (value) => currentTenantRef.current = value

    if([null, undefined].includes(oidcConfigRef.current)){
        setState(prev => OPENID_STATE.LOADING)
        oidcConfigRef.current = {
            ...defaultConfigs,
            ...kbtgTenant,
        }
        console.log("Auth System: Default Check: currentTenantRef", currentTenantRef.current, " oidcConfigRef.current: ", oidcConfigRef.current?.authority)
        setCurrentTenant("kbtg")
    }

    const getConfigs = (tenantName) => {
        let newConfig;
        switch(tenantName){
            case 'kbtg':
                    newConfig = kbtgTenant;
                    setCurrentTenant(tenantName)
                    break;
            case 'sap':
                    newConfig = kbtgTenant;
                    setCurrentTenant(tenantName)
                    break;
            default:
                console.log("OpenIdProvider: defaulting to neufast tenant")
                newConfig = neufastTenant
                setCurrentTenant("neufast")
        }
        return newConfig;
    }

    const changeTenant = (tenantName) => {
        console.log("Auth System: LoginPage: changing tenant: ", tenantName)
        setState(prev => OPENID_STATE.LOADING)
        let newConfig = getConfigs(tenantName);
        oidcConfigRef.current = {
            ...defaultConfigs,
            ...newConfig,
        }
        console.log("Auth System: Default Check: changeTenant authority:", oidcConfigRef.current?.authority, " tenantName: ", tenantName)
        setState(prev => OPENID_STATE.READY)
    }

    /**
     * Stores the passed search params in a
     * sso cookie
     * @param {URLSearchParams} urlSearchParams 
     */
    const storeSearchParams = (urlSearchParams) => {
        const isProd = process.env.NODE_ENV === 'production'
        console.log("OpenIdConnectLoginHandler: querySearchParams: storeSearchParams: ", urlSearchParams)
        setCookie('ssoSearchParams', urlSearchParams, isProd, 'lax', false)
    }

    /**
     * Retrieves the sso cookie, parses it into
     * an object and returns the search params
     * @returns {Object} searchParams
     */
    const retrieveSearchParams = () => {
        let cookieData = getCookie('ssoSearchParams')
        let params = {}
        for (let [key, value] of (new URLSearchParams(cookieData))) {
            params[key] = value
        }
        return params
    }

    /**
     * Deletes the sso cookie
     */
    const deleteSearchParams = () => {
        removeCookie('ssoSearchParams')
    }

     /**
      * Generates redirection link dependent on
      * current search params Error Codes:
      * 0 = No Error
      * 1 = invalid params for interview-report
     */
    const generateRedirectLink = () => {
       
        let linkData = {
            'errorCode': 0,
            'link': '/recruiter/settings'
        }
        const searchParams = retrieveSearchParams()
        deleteSearchParams()
        console.log("OpenIdConnectLoginHandler: querySearchParams: storeSearchParams: generateRedirectLink: ", searchParams, " searchParams: ", searchParams.redirect)
        try {
            /**
             * If searchParams are absent,
             * return the default link
             */
            if(
                !searchParams ||
                searchParams === undefined ||
                searchParams === null
            ){
                console.log("OpenIdConnectLoginHandler: querySearchParams: storeSearchParams: query params are absent")
                return linkData
            }

            console.log("OpenIdConnectLoginHandler: querySearchParams: storeSearchParams: jsonobject: ", searchParams)
            /**
             * Redirection to Candidate Report Page
             * If redirect is interview-report,
             * verify the presence of required params
             * & generate the link
             */
            if(
                searchParams
                && searchParams?.redirect
                && searchParams.redirect === 'interview-report'
            ){
                if(
                    Object.keys(searchParams).length === 4
                    && searchParams?.interviewId
                    && searchParams?.email
                    && searchParams?.jobId
                ){
                    /**
                     * Example: /recruiter/dashboard/interview-report?email=testing-1@neufast.com&jobId=Testing SSO_20250211100306&interviewId=interview_46190462-e860-11ef-8970-0242ac120005
                     */
                    linkData.link = `/recruiter/dashboard/interview-report?email=${searchParams.email}&jobId=${searchParams.jobId}&interviewId=${searchParams.interviewId}`
                } else {
                    linkData.errorCode = 1
                }
            }
        } catch (error) {
            console.error("OpenIdConnectLoginHandler: querySearchParams: generateRedirectLink: error: ", error)
        }
        return linkData
    }

    useMemo(() => {
        console.log("Auth System: Default Check: authority: ", oidcConfigRef.current?.authority)
    }, [oidcConfigRef.current?.authority])

    /**
     * 'key' prop needs to be passed to re-render the AuthProvider component
     * when switching between different authorities
     */
    return useMemo(() => {
        console.log("Auth System: refreshed: oidcConfigRef.current: ", oidcConfigRef.current?.authority, " currentTenantRef: ", currentTenantRef.current, " domain: ", state)
        return (
            <>
            <OpenIdInstanceContext.Provider value={{
                oidcConfig: oidcConfigRef.current,
                OPENID_STATE,
                state,
                currentTenant: currentTenantRef.current,
                getConfigs,
                changeTenant,
                storeSearchParams,
                retrieveSearchParams,
                deleteSearchParams,
                generateRedirectLink,
            }}>
                <AuthProvider {...oidcConfigRef.current} key={currentTenantRef.current}>
                    {children}
                </AuthProvider>
            </OpenIdInstanceContext.Provider>
            </>

        )
    }, [oidcConfigRef.current])
}

/**
 * Hook to access OpenId state variables & utility functions
 * @returns
 */
export const useOpenId = () => {
    const context = useContext(OpenIdInstanceContext);
    if(!context){
        throw new Error('useOpenId must be used within an OpenIdProvider provider');
    }
    return context;
}
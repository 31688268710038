import Cookies from 'js-cookie'

/**
 * Custom Cookie methods to overwrite the default
 * cookie getter and setter 
 * @param {*} name 
 * @returns 
 */

export const getCookie = name => Cookies.get(name); 

export const setCookie = (name, value, secure = true, sameSite = 'lax', httpOnly=false) => {
    return Cookies.set(name, value, {
      path: '/',
      secure: secure,
      sameSite: sameSite,
      httpOnly: httpOnly,
    });
  };

export const removeCookie = (name) => {
    Cookies.remove(name)
}